import { Check } from "@phosphor-icons/react";
import * as Sentry from "@sentry/astro";
import { useEffect, useState } from "react";

import { useTranslations } from "@/i18n/utils";

import Button from "@/components/shared/buttons/button";
import Input from "@/components/shared/inputs/input";
import Paragraph from "@/components/shared/text/paragraph";

interface FormWaitingListProps {
  lang: "en" | "es";
}

const WAITING_URL = import.meta.env.PUBLIC_WAITING_LIST;

if (!WAITING_URL) {
  Sentry.captureMessage(
    "PUBLIC_WAITING_LIST environment variable is not defined",
    "error",
  );
}

const FormWaitingList = ({ lang }: FormWaitingListProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<"error" | "default">("default");
  const [utmSource, setUtmSource] = useState<string | null>(null);

  const t = useTranslations(lang);

  const BASE_TRANSLATE = "home.callToAction";

  const PLACEHOLDER = String(t(`${BASE_TRANSLATE}.input.placeHolder`));
  const BUTTON = String(t(`${BASE_TRANSLATE}.button`));
  const BUTTON_SUCCESS = String(t(`${BASE_TRANSLATE}.buttonSuccess`));
  const ERROR = String(t(`${BASE_TRANSLATE}.error.500`));
  const SUCCESS = String(t(`${BASE_TRANSLATE}.success`));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUtmSource(params.get("utm_source"));
  }, []);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("default");
    setSuccess(false);

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email-waiting-list") as string;

    if (!email || !WAITING_URL) {
      Sentry.captureMessage("Missing email or WAITING_URL", "warning");
      return;
    }

    try {
      const response = await fetch(WAITING_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, lang, utmSource }),
      });

      if (response.status === 202) {
        setSuccess(true);
        setError("default");
        return;
      }

      const errorDetails = `HTTP error! status: ${response.status}`;
      Sentry.setContext("formData", { email, lang });
      Sentry.setTag("feature", "join-waiting-list");
      Sentry.captureException(new Error(errorDetails));
      setError("error");
    } catch (error) {
      console.error("Error:", error);
      setError("error");
      Sentry.setContext("formData", { email, lang });
      Sentry.setTag("feature", "join-waiting-list");
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <form
        id="form-waiting-list"
        method="POST"
        onSubmit={handleFormSubmit}
        className="col-span-full grid grid-cols-[4fr_2fr] gap-0 pb-3 pt-6 md:grid-cols-subgrid"
      >
        <Input
          className="md:col-span-3"
          classNameInput="rounded-r-none border-r-0"
          id="email-waiting-list"
          name="email-waiting-list"
          type="email"
          placeholder={PLACEHOLDER}
          status={error}
        />

        <Button
          buttonType="primary"
          type="submit"
          color="white"
          className="-ml-1 h-full md:col-span-1 md:min-w-32"
          size="base"
          weightIcon="bold"
          leftIcon={success ? Check : undefined}
          label={success ? BUTTON_SUCCESS : BUTTON}
        />
      </form>
      {error === "error" && (
        <Paragraph size="xs" className="col-span-full text-danger-80">
          {ERROR}
        </Paragraph>
      )}
      {success && (
        <Paragraph size="xs" className="col-span-full text-white-70">
          {SUCCESS}
        </Paragraph>
      )}
    </>
  );
};

export default FormWaitingList;

import { getLangFromUrl, useTranslations } from "@/i18n/utils";

import Heading from "@/components/shared/text/heading";

interface BenefitsHeaderProps {
  url: URL;
}

export default function BenefitsHeader({ url }: BenefitsHeaderProps) {
  const lang = getLangFromUrl(url);
  const t = useTranslations(lang);

  const BASE_TRANSLATE = "home.benefitsSection";

  const TITLE_ONE = String(t(`${BASE_TRANSLATE}.titleOne`));
  const TITLE_TWO = String(t(`${BASE_TRANSLATE}.titleTwo`));
  const TITLE_COLOR = String(t(`${BASE_TRANSLATE}.titleColor`));

  return (
    <div className="benefits-header col-span-full justify-items-center">
      <div className="mx-auto w-80 max-w-80 sm:w-full sm:max-w-full">
        <Heading
          weight="semiBold"
          className="text-center text-white md:text-headingLg"
        >
          {TITLE_ONE}
          <span className="text-lime-100 md:text-headingLg">
            {" "}
            {TITLE_COLOR}{" "}
          </span>
          <span className="text-center text-white md:text-headingLg">
            {""}
            {TITLE_TWO}
          </span>
        </Heading>
      </div>
    </div>
  );
}
